@use "src/_app/styles/mixins.scss" as *;
.statistic {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 17px;

  @include respond(tablet) {
    grid-template-columns: 1fr;
    display: flex;
    flex-flow: column;
  }

  &__item {
    height: 114px;
    padding: 16px 16px 44px;
    border-radius: 16px;
    display: flex;
    flex-flow: column;
    background-color: var(--block_bg_cards);

    @include respond(laptopC) {
      height: 90px;
      padding: 8px 4px 32px 8px;
    }

    @include respond(tablet) {
      height: 90px;
      padding: 16px 16px 40px;
    }

    &__header {
      display: flex;
      justify-content: space-between;
    }

    .statusTitle {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: var(--text_1);
    }

    h3 {
      margin: 6px 0 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &__item_tron {
    @include respond(tablet) {
      height: auto;
    }
  }
}

.tron_status {
  display: flex;
  gap: 8px;

  &__props {
    display: flex;
    gap: 8px;

    &__label,
    &__value {
      margin: 6px 0 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: var(--text_1);
    }

    &__value {
      font-weight: 500;

      &_success {
        color: var(--success);
      }

      &_error {
        color: var(--negative);
      }
    }
  }
}

.icon {
  display: flex;
  justify-content: flex-end;
  color: var(--success);

  svg {
    animation: 3s linear infinite rotate;
  }

  @include respond(tablet) {
    width: 58px;
  }

  &_check {
    svg {
      animation: unset;
      transform: rotate(0deg);
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.additional_wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 17px;

  @include respond(mobileL) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
