@use "src/_app/styles/mixins.scss" as *;
.table {
  border-radius: 20px;
  background: var(--table_bg);
  border: 1px solid var(--border_13);
  overflow: hidden;

  h4 {
    margin: 0;
    padding: 20px;
  }

  &__item {
    padding: 16px 20px;
    border-top: 1px solid var(--border_13);
  }

  .show_all {
    @include tButton_2;
    color: var(--primary);
    padding: 16px;
    cursor: pointer;
    background-color: transparent;
    width: 100%;
    border: none;
    border-top: 1px solid #e8e8e8;

    button {
      outline: none;
    }
  }
}
