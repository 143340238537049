@use "src/_app/styles/mixins.scss" as *;
.card {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  color: var(--primary);
  position: relative;
  z-index: 0;

  &.neutral {
    background-color: var(--bg_neutral_10);
    border: 1px solid var(--border_14);
  }

  &.block {
    background-color: var(--bg_block);
  }
}

.cardBody {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 20px;

  .icon {
    margin-bottom: 4px;
  }

  .title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--primary);
  }

  h5 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  @include respond(mobile) {
    padding: 16px;
  }
}

.cardFooter {
  margin-top: 22px;
  display: flex;
}

.children {
  margin-top: auto;
}
