@use "src/_app/styles/mixins.scss" as *;
@mixin shadow_overlay($end) {
  content: '';
  background: linear-gradient(
    360deg,
    var(--bg_24) 20%,
    rgb(255 255 255 / 0%) $end
  );
  width: 100%;
  height: 155px;
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.group_A {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.stake_with_cryptomus_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    @include tTitle_1_bold;
    line-height: 72px;
    text-align: center;
    margin: 0 0 16px;
    max-width: 875px;
  }

  p {
    @include tSubtitle-3-medium;
    max-width: 676px;
    text-align: center;
    margin: 0;
  }

  @include respond(laptop) {
    h2 {
      font-size: 48px;
      line-height: 60px;
    }

    p {
      font-size: 20px;
      line-height: 30px;
    }
  }

  @include respond(mobileL) {
    h2 {
      font-size: 28px;
      line-height: 36px;
      text-align: left;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      text-align: left;
    }
  }

  .wrapper {
    display: flex;
    gap: 24px;
    max-width: 100%;
    margin-top: 56px;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    flex-wrap: nowrap;

    @include respond(tabletL) {
      margin-top: 32px;
      gap: 16px;
      flex-wrap: wrap;
    }

    svg {
      width: 100%;
    }
  }

  .container {
    background-color: var(--bg_24);
    max-width: 519px;
    min-height: 426px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.reverse {
  color: var(--primary);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.safety,
  &.withdraw {
    height: 402px;
    min-height: 402px;

    @include respond(laptop) {
      min-height: 426px;
    }

    @include respond(tabletL) {
      min-height: 402px;
    }
  }

  .chat_bubble {
    position: relative;
    z-index: 2;

    svg {
      position: absolute;
      left: -4px;
      bottom: -2px;
      z-index: -1;
    }
  }

  @include respond(tabletL) {
    flex-direction: column-reverse;
    position: relative;

    &.support {
      min-height: 310px;
      height: 310px;

      .backdrop {
        svg {
          width: 512px;
          height: 268px;
          margin-top: 18px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%) rotate(180deg);
        }
      }
    }

    .safety_wrapper {
      padding: 16px;
    }

    &.safety {
      min-height: 330px;
      height: 330px;

      .img_wrapper {
        height: auto;
      }
    }

    &.withdraw {
      &::after {
        @include shadow_overlay(30%);
      }

      min-height: 315px;
      height: auto;

      .window_A {
        display: none;
      }

      .action_wrapper {
        top: 98px;
      }

      .cursor {
        top: 140px;
      }

      .window_B {
        left: 20px;
        top: 30px;
        z-index: 2;
        max-width: calc(100% - 40px);

        .mock_window {
          height: 186px;
          width: 500px;
        }
      }

      .img_wrapper {
        height: 216px;
      }
    }

    &.validator {
      min-height: 265px;
      height: 320px;

      .img_wrapper {
        height: 110px;
      }

      .staking_card {
        top: 26px;

        &:nth-child(2) {
          left: 49px;
          max-width: calc(100% - 98px);
          width: 500px;
          top: 16px;
        }

        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
}

.img_wrapper {
  position: relative;
  height: 145px;
  width: 100%;
  flex-grow: 1;
}

.chat_wrapper {
  position: absolute;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .chat_bubble {
    display: inline-block;
    text-align: center;
    background: var(--bg_40);
    border-radius: 16px;
    color: white;
    padding: 10px;
    margin: 4px 16px;
  }
}

.safety_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px;

  &::after {
    @include shadow_overlay(100%);
  }

  @include respond(tabletL) {
    &::after {
      @include shadow_overlay(30%);
    }
  }

  .coin {
    .coin__inner {
      display: flex;
      gap: 16px;
    }

    .img_container {
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }

    .safety_img {
      width: 100%;
      height: 100%;
      background-size: 18px;
      background-position: center;
      background-repeat: no-repeat;
    }

    &:nth-child(1) {
      .img_container {
        background: #f7931a;

        .safety_img {
          background-image: url('/icons/coins/bitcoin-1.svg');
        }
      }
    }

    &:nth-child(2) {
      .img_container {
        background: #50af95;

        .safety_img {
          background-image: url('/icons/coins/tether.svg');
        }
      }
    }

    &:nth-child(3) {
      .img_container {
        background: #8247e5;

        .safety_img {
          background-image: url('/icons/coins/polygon.svg');
        }
      }
    }

    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    width: 386px;
    max-width: 100%;
    background: var(--bg_21);
    border-bottom: 1px solid var(--bg_7);

    &:first-child {
      border-radius: 16px 16px 0 0;
    }

    &:last-child {
      border-radius: 0 0 16px 16px;
    }

    span {
      width: 100%;
      padding: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 70px;

      &:last-child {
        width: 80px;
      }
    }
  }
}

.description {
  padding: 32px 42px;
  text-align: center;
  z-index: 2;

  @include respond(tabletL) {
    padding: 22px 24px 0;
  }
}

.card_title {
  @include tTitle_3-semibold;
  margin-bottom: 8px;
  padding: 0 50px;

  @include respond(tabletL) {
    padding: 0;
  }
}

.card_description {
  @include tSubtitle;
}

.bubble_A {
  position: absolute;
  width: 29.34px;
  height: 29.34px;
  right: -25px;
  top: -70px;

  background: linear-gradient(
    180deg,
    var(--bg_bubbleA) 0%,
    var(--bg_bubbleB) 100%
  );
  border-radius: 16px;
  transform: matrix(0.86, 0.5, 0.5, -0.86, 0, 0);

  @include respond(tabletL) {
    display: none;
  }
}

.group {
  display: flex;
  gap: 24px;
  max-width: 100%;
  flex-direction: column;

  @include respond(tabletL) {
    flex-direction: column;
    gap: 16px;
  }
}

.bubble_B {
  position: absolute;
  width: 16px;
  height: 16px;
  left: -25px;
  top: -58px;

  background: linear-gradient(
    180deg,
    var(--bg_bubbleB) 0%,
    var(--bg_bubbleB) 100%
  );
  border-radius: 16px;
  transform: matrix(0.86, 0.5, 0.5, -0.86, 0, 0);

  @include respond(tabletL) {
    display: none;
  }
}

.mock_window {
  width: 400px;
  height: 199px;
  border-radius: 10px;
  background: var(--bg_31);
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 4%);
  max-width: 100%;

  .buttons {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  span {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-flex;
    margin: 0 4px;

    &:nth-child(1) {
      background: var(--negative);
    }

    &:nth-child(2) {
      background: var(--warning);
    }

    &:nth-child(3) {
      background: var(--success);
    }
  }
}

.window_A {
  position: absolute;
  left: 32px;
  top: 32px;
  max-width: calc(100% - 64px);

  .mock_window {
    background: var(--bg_38);

    span {
      background: var(--bg_32);
    }
  }

  z-index: 1;
}

.window_B {
  position: absolute;
  left: 67px;
  top: 72px;
  z-index: 2;
  max-width: calc(100% - 130px);
}

.stripe {
  position: absolute;
  left: 256px;

  @include respond(tablet) {
    display: none;
  }
}

.action_wrapper {
  position: absolute;
  top: 142px;
  z-index: 2;
  padding: 4px;
  height: 50px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action {
  padding: 5px;
  display: flex;
  border-radius: 8px;
  border: 2px solid var(--primary);

  span {
    background: var(--primary);
    color: var(--secondary);
    padding: 12px 22px;
    border-radius: 8px;

    @include tSubtitle_Semibold;
  }
}

.cursor {
  position: absolute;
  right: 84px;
  top: 186px;
  z-index: 4;

  @include respond(tabletL) {
    right: 20px;
    top: 165px;
  }
}

.staking_card {
  background: var(--bg_31);
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 25px;
  width: 386px;
  left: 66px;
  top: 109px;
  position: absolute;
  z-index: 2;
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 4%);
  max-width: calc(100% - 132px);

  @include respond(tabletL) {
    left: 33px;
    max-width: calc(100% - 66px);
    width: 100%;
  }

  &:nth-child(2) {
    top: 98px;
    width: 354px;
    left: 80px;
    z-index: 1;
    max-width: calc(100% - 160px);
  }

  &:nth-child(3) {
    top: 90px;
    width: 324px;
    left: 94px;
    z-index: 0;
    max-width: calc(100% - 188px);
  }

  .icon_wrapper {
    background: rgb(243 186 47 / 100%);
    height: 60px;
    width: 60px;

    @include respond(tabletS) {
      height: 40px;
      width: 40px;
    }

    border-radius: 50%;
  }

  .icon {
    background-size: 38px;
    background-image: url('/icons/coins/binance.svg');

    @include respond(tabletS) {
      background-size: 28px;
    }

    width: 100%;
    background-position: center;
    height: 100%;
    background-repeat: no-repeat;
  }

  .content {
    display: flex;
    flex-direction: column;
    color: black;
  }

  .destination_name {
    @include tTitle_3_demibold;
    color: var(--primary);
  }

  .validator {
    @include tCaption;
    color: var(--primary);
  }
}
