@use "src/_app/styles/mixins.scss" as *;
.container {
  padding: 24px;
  display: flex;
  flex-flow: column;
  gap: 16px;
  background-color: var(--bg_block);
  border-radius: 20px;

  @include respond(mobileL) {
    padding: 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    @include respond(tabletS) {
      flex-flow: column;
      justify-content: flex-start;
      gap: 16px;
    }

    &__coin {
      display: flex;
      gap: 16px;

      &__text {
        display: flex;
        flex-flow: column;
        gap: 2px;

        h2 {
          margin: 0;
        }

        span {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
          color: var(--text_1);
        }
      }
    }

    &__session {
      display: flex;
      flex-flow: column;
      gap: 4px;

      &__date_diff {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;

        @include respond(tabletS) {
          justify-content: flex-start;
        }
      }

      span {
        text-align: end;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;

        @include respond(tabletS) {
          text-align: start;
        }
      }

      &__date_at {
        text-align: end;
        font-weight: 400 !important;
        color: var(--text_1);

        @include respond(tabletS) {
          text-align: start;
        }
      }
    }
  }

  &__hash {
    gap: 4px;

    h4 {
      margin: 0;
      line-height: 24px;
      font-weight: 500;
    }

    &__bottom_block {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      span {
        font-weight: 400;
      }

      svg {
        color: var(--tertiary);
      }
    }
  }

  .block {
    padding: 12px 20px;
    border-radius: 14px;
    display: flex;
    flex-flow: column;
    background-color: var(--block_bg_cards);
  }
}
