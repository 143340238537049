@use "src/_app/styles/mixins.scss" as *;
.transactions {
  &_tron {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: var(--bg_block);
    border-radius: 20px;
  }

  &__header {
    display: flex;
    flex-flow: column;

    &__label {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      text-align: start;
      color: var(--primary);
    }

    &__value {
      margin: 0;

      @include tSubtitle;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    @include respond(tabletS) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }

    .buttonCopy {
      cursor: pointer;
    }
  }

  &__row {
    padding: 12px 20px;
    display: flex;
    flex-flow: column;
    gap: 4px;
    background-color: var(--block_bg_cards);
    border-radius: 16px;

    &__label {
      @include tSubtitle;
      margin: 0;
      color: var(--text_1);
    }

    &__copy {
      display: flex;
      gap: 10px;

      &__label {
        margin: 0;

        @include tSubtitle;
        color: var(--primary);

        @include respond(laptop) {
          display: none;
        }
      }

      &__label_mobile {
        display: none;

        @include respond(laptop) {
          display: flex;
        }
      }

      svg {
        color: var(--tertiary);
      }
    }
  }
}

.smart_tooltip {
  &__title {
    display: flex;
    justify-content: space-between;
    column-gap: 4px;
    align-items: center;
    cursor: pointer;

    .title__value {
      word-break: break-all;
    }
  }
}
