@use "src/_app/styles/mixins.scss" as *;
.wrapper {
  display: flex;
  gap: 24px 16px;
  margin-top: 36px;

  & > * {
    gap: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  @include respond(tablet) {
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }

  @include respond(tabletS) {
    flex-direction: row;
    margin-top: 36px;
  }

  .accordionItem__content {
    display: flex;
    justify-content: space-between;
  }

  .content__text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .content__title {
    color: #b1b1b1;
  }

  .content__description {
    color: #fff;
  }
}

div.skeleton {
  border-radius: 20px;
  background-image: linear-gradient(
    90deg,
    #292929 0,
    #212121 40px,
    #292929 80px
  );
  background-size: 200%;
  width: 100%;
  height: 64px;
}
