@use "src/_app/styles/mixins.scss" as *;
.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;

  @include respond(tabletS) {
    justify-content: center;
    flex-direction: column;
    gap: 32px;
  }
  gap: 20px;

  & > :first-child {
    max-width: 100px;
    width: 100%;
  }

  & > :last-child {
    max-width: 150px;
    width: 100%;
  }
}

.text__container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 6px;
}

.title {
  color: #b1b1b1;
}

.description {
  color: #fff;
}

.longLang {
  word-break: break-all;
}
