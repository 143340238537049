@use "src/_app/styles/mixins.scss" as *;
.chat__border {
  background: var(--border_7);
  border-radius: 24px;
}

.chat__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 46px;
  padding: 32px;

  background: var(--bg_16);

  border-radius: 24px;
  box-shadow:
    0 4px 29px rgb(0 0 0 / 9%),
    0 0 12px rgb(37 33 41 / 4%);

  @include respond(tablet) {
    row-gap: 24px;
    padding: 24px 16px;
  }
}

.day {
  color: var(--text_1);
  background: var(--bg_17);
  border-radius: 52px;
  padding: 6px 20px;
  margin: 0;

  @include tSubtitle;
}

.chat {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;

  .user_icon img {
    width: 40px;
    height: 40px;

    @include respond(mobileL) {
      width: 36px;
      height: 36px;
    }
  }
}

.message_friend {
  display: flex;
  align-items: center;
}

.message {
  display: flex;
  padding: 8px 10px;
  max-width: 365px;
  gap: 4px;

  @include respond(tablet) {
    max-width: 244px;
  }

  margin-left: 8px;

  @include respond(mobileL) {
    margin-left: 4px;
  }

  .message__text {
    margin: 0;
    z-index: 1;

    @include tSubtitle;

    @include respond(tablet) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .message__date {
    @include tChatTime;
    color: var(--text_1);
    margin: 8px 0 0;

    @include respond(tablet) {
      margin-top: 4px;
    }
  }

  background: #ebebeb;
  border-radius: 16px;
  position: relative;

  .corner {
    position: absolute;
    bottom: -1px;
    left: -5px;
    z-index: 0;
  }
}

.message_me {
  flex-direction: row-reverse;
  align-items: flex-end;
  display: flex;

  &_null_image {
    margin-right: 40px;
  }

  .message {
    background: var(--mainPrimary);
    align-items: flex-end;
    padding: 8px 10px;
    gap: 4px;

    margin-right: 8px;

    @include respond(mobileL) {
      margin-right: 4px;
    }
  }

  .message__text {
    color: var(--mainSecondary);
    margin: 0;

    @include tSubtitle;
    z-index: 1;

    @include respond(tablet) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .message__link {
    text-decoration: underline;

    @include respond(tablet) {
      max-width: 191px;
    }
  }

  border-radius: 16px;
  position: relative;

  .corner {
    path {
      fill: var(--mainPrimary);
    }

    transform: scale(-1, 1);
    position: absolute;
    bottom: -1px;
    right: -5px;
    left: unset;
    z-index: 0;
  }
}

.message__group {
  display: flex;
  flex-direction: column;

  .message {
    &:first-child {
      margin-bottom: 4px;
    }
  }
}
