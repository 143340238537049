@use "src/_app/styles/mixins.scss" as *;
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 62px;

  @include respond(tablet) {
    grid-template-columns: 1fr;
  }
}
