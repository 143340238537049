@use "src/_app/styles/mixins.scss" as *;
.converter {
  background-image: url('/icons/services/personal/converter-background.png');
  background-repeat: no-repeat;
  background-position: right;

  padding: 100px 80px;

  @include respond(laptop) {
    padding: 100px 32px;
  }

  @include respond(tablet) {
    padding: 40px 16px;
    background-image: none;
  }

  &.buyPage {
    background-image: url('/icons/buy/converter-background.png');
    background-position: center bottom 150px;

    @include respond(tablet) {
      background-image: none;
    }
  }

  &__container {
    max-width: 1280px;
    margin: 0 auto;

    display: grid;
    grid-template-columns: minmax(250px, 1fr) minmax(300px, 628px);
    column-gap: 53px;
    align-items: center;

    @include respond(laptop) {
      grid-template-columns: minmax(250px, 1fr) minmax(300px, 468px);
    }

    @include respond(tablet) {
      grid-template-columns: 1fr;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &.short {
      min-width: 250px;
    }
  }

  &__calculator {
    @include respond(tablet) {
      order: -1;
    }
  }

  &__titleContainer {
    hyphens: auto;
  }

  &__title {
    @include tTitle_1_banner;
    margin: 0 0 32px;
    color: var(--white);

    @include respond(laptopC) {
      @include tTitle_1_main;
      margin-bottom: 24px;
    }

    @include respond(laptop) {
      @include tTitle_2_bold;
    }

    &.long {
      @include respond(laptopL) {
        @include tTitle_1_main;
        margin-bottom: 24px;
      }

      @include respond(tabletL) {
        @include tTitle_2_bold;
      }
    }

    &.mobileTitle {
      order: -1;
      display: none;

      @include respond(tablet) {
        display: block;
      }
    }

    &.laptopTitle {
      @include respond(tablet) {
        display: none;
      }
    }
  }

  &__subTitle {
    @include Land_32;
    margin: 0 0 20px;
    color: var(--white);

    @include respond(laptopC) {
      @include tTitle_2_Semibold;
      margin-bottom: 16px;
    }

    @include respond(tablet) {
      @include tSubtitle-3-medium;
    }

    &.long {
      @include respond(laptopL) {
        @include tTitle_2_Semibold;
        margin-bottom: 16px;
      }

      @include respond(tabletL) {
        @include tSubtitle-3-medium;
      }
    }
  }

  &__textWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 32px;

    p {
      color: var(--white);
      margin: 0;

      @include tTitle_3;
    }
  }

  &__button {
    display: block;
    max-width: max-content;
    min-width: 100px;

    @include respond(tablet) {
      max-width: 100%;
    }
  }
}

body {
  a.signUp_button {
    color: var(--white);
    outline: 1px solid var(--white);

    &:hover {
      background: #1a1a1a;
    }
  }
}
