@use "src/_app/styles/mixins.scss" as *;
.container {
  border-radius: 16px;
  background: #191919;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 16px;
  gap: 16px;

  @include respond(tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond(tabletS) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
