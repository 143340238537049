@use "src/_app/styles/mixins.scss" as *;
.swiper_responsive {
  .swiper_list {
    &:first-of-type {
      display: flex;
    }

    &:last-of-type {
      display: none;
    }
  }
}

.swiper_list {
  display: flex;
  justify-content: space-between;
  background: var(--secondary);
  padding: 16px;
  margin: -16px;
}
