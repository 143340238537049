@use "src/_app/styles/mixins.scss" as *;
.select {
  outline: 1px solid var(--border_1);
  border-radius: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 22px 18px 16px;

  svg path {
    fill: var(--primary);
  }

  p {
    margin: 0;
    color: var(--primary);
  }

  &_dark {
    outline: 1px solid #444;

    svg path {
      fill: var(--white);
    }

    p {
      color: var(--white);
    }
  }
}
