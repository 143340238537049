@use "src/_app/styles/mixins.scss" as *;
.wrapper {
  background-image: url('/img/affiliate/faq-bg.webp');
  background-size: 1920px 1072px;
  background-position: top center;
  position: relative;
  z-index: 10;
  background-repeat: no-repeat;
  width: 100%;

  @media only screen and (width > 1920px) {
    mask-composite: intersect;
    /* stylelint-disable-next-line */
    mask-image:
      linear-gradient(to right, black 70%, transparent 85%),
      linear-gradient(to left, black 70%, transparent 90%);
    mask-repeat: no-repeat;
  }

  @include respond(tablet) {
    background-image: url('/img/affiliate/faq-bg-mobile.webp');
    background-size: 100% auto;
  }

  &::after {
    pointer-events: none;
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 84px;
    background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
    z-index: 20;
  }

  margin-top: 56px;

  @include respond(tablet) {
    margin-top: 32px;
  }

  @include respond(tabletS) {
    margin-top: 40px;
  }
}

.container {
  max-width: 1062px;

  @include respond(tablet) {
    max-width: none;
  }
}

.accordions__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  padding-bottom: 120px;
  margin-top: 56px;

  @include respond(tablet) {
    padding-bottom: 56px;
  }

  @include respond(tabletS) {
    margin-top: 36px;
  }

  .accordion__header {
    text-align: start;
    color: #fff;

    @include respond(tabletS) {
      max-width: 80%;
    }
  }

  .accordion__wrapper {
    border-bottom: 1px solid #2d2d2d;
    color: #fff;

    .accordion__button {
      padding-top: 24px;

      svg {
        path {
          min-width: 24px;
          fill: #fff;
        }
      }
    }

    .accordion__content {
      color: #fff;
      padding-bottom: 24px;
    }
  }
}

.title {
  padding-top: 120px;
  max-width: 770px;

  @include respond(tablet) {
    max-width: none;
    padding-top: 56px;
  }
}
