@use "src/_app/styles/mixins.scss" as *;
.container {
  margin-top: 112px;

  @include respond(tablet) {
    margin-top: 80px;
  }

  @include respond(tabletS) {
    margin-top: 64px;
  }
}

.title {
  max-width: 700px;
}

.description {
  margin-top: 36px;

  @include respond(tablet) {
    margin-top: 24px;
  }

  @include respond(tabletS) {
    margin-top: 32px;
  }
}

.infoCards__container {
  margin-top: 24px;

  @include respond(tablet) {
    margin-top: 16px;
  }

  @include respond(tabletS) {
    margin-top: 28px;
  }
}
