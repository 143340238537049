@use "src/_app/styles/mixins.scss" as *;
.tabs {
  margin-bottom: 14px;
  margin-inline-start: -16px;
  width: 100vw;
  height: 44px;
  padding-inline-start: 16px;
  display: flex;
  gap: 24px;
  border-bottom: 1px solid var(--border_13);

  button {
    position: relative;
    width: max-content;
    height: 44px;
    background: none;
    border: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--primary);

    .underline {
      position: absolute;
      width: 100%;
      height: 3px;
      display: block;
      background: var(--primary);
      left: 0;
      bottom: 0;
      border-radius: 6px 6px 0 0;
    }
  }
}
