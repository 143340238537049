@use "src/_app/styles/mixins.scss" as *;
.questions {
  background-color: var(--bg_18);
  padding: 100px 0;

  @include respond(laptopL) {
    padding: 100px 80px;
  }

  @include respond(laptop) {
    padding: 100px 32px;
  }

  @include respond(tablet) {
    padding: 0;
    row-gap: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 48px;

    max-width: 1280px;

    margin: 0 auto;

    @include respond(tablet) {
      row-gap: 0;
    }
  }
}
