@use "src/_app/styles/mixins.scss" as *;
.content__item {
  color: var(--text_1);
  position: relative;

  @include tSubtitle;
  @include transition;
  cursor: pointer;
  word-wrap: break-word;

  &:hover,
  &:active {
    color: var(--primary);
  }

  &.inView {
    color: var(--primary);
  }

  .active_line {
    position: absolute;
    inset-inline-start: -24px;
    top: 0;
    width: 3px;
    height: 100%;
    background: var(--primary);
    border-radius: 0 10px 10px 0;
  }
}
