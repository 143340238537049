@use "src/_app/styles/mixins.scss" as *;
.comments_list_item_respond_form {
  display: grid;
  grid-template-columns: max-content calc(100% - 16px - 44px);
  gap: 16px;
  padding-top: 12px;

  @include respond(tabletS) {
    margin-left: 24px;
    padding-top: 16px;
  }

  .comments_list_item_respond_form_content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 16px;
    border: var(--blogCommentBorder);
    background: var(--bg_10);
    border-radius: 16px;
    box-shadow:
      0 0 12px 0 rgb(37 33 41 / 4%),
      0 16px 32px 0 rgb(37 33 41 / 8%);

    position: relative;

    @include respond(tabletS) {
      min-height: 136px;
      justify-content: space-between;
    }

    .comments_list_item_respond_form_shadow {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      border-radius: 16px;
      box-shadow:
        0 0 12px 0 rgb(37 33 41 / 4%),
        0 16px 32px 0 rgb(37 33 41 / 8%);
    }

    textarea {
      width: 100%;
      border: none;
      resize: none;
      outline: none;
      max-height: 200px;
      background: transparent;
      color: var(--primary);
      overflow: hidden;
    }

    &_buttons {
      display: flex;

      button {
        font-family: Golos, sans-serif;
      }

      &_reply {
        margin-left: 8px;
      }
    }
  }
}
