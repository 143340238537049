@use "src/_app/styles/mixins.scss" as *;
.second__block {
  &__container {
    display: grid;
    grid-template-columns: 443px 1fr;
    column-gap: 27px;

    padding: 56px 32px 56px 35px;

    color: var(--primary);
    background-color: var(--bg_3);
    background-image: url('/icons/services/personal/questions-bg-2.png');
    border-radius: 24px;
    background-repeat: no-repeat;
    background-size: cover;

    @include respond(laptop) {
      grid-template-columns: 348px 1fr;
    }

    @include respond(tablet) {
      grid-template-columns: 1fr;
      row-gap: 24px;
      background-image: none;
      padding: 40px 16px;
      border-radius: 0;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &__title {
    margin: 0;

    @include tTitle_1_main;

    @include respond(laptop) {
      font-size: 40px;
    }

    @include respond(tablet) {
      font-size: 28px;
      line-height: 100%;
    }
  }

  &__subtitle {
    margin: 0;

    @include tSubtitle;
  }

  &__accordion {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &__row {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      overflow: hidden;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        @include tTitle_3-semibold;
        margin: 0;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      p {
        margin: 0;

        @include tButton_2;
        line-height: 24px;
      }
    }
  }
}
