@use "src/_app/styles/mixins.scss" as *;
.icon {
  background: transparent;
  outline: none;
  border: none;
  position: relative;
  height: 40px;

  svg {
    cursor: pointer;
  }

  .qr {
    z-index: 20;
  }
}
