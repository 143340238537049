@use "src/_app/styles/mixins.scss" as *;
.container {
  display: flex;
  gap: 15px;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  .icon__wrapper {
    border-radius: 100%;
    min-width: 64px;
    min-height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #242424;
    color: #fff;

    @include respond(tabletS) {
      min-width: 42px;
      min-height: 42px;
    }
  }
}

.text__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.text__title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}
