@use "src/_app/styles/mixins.scss" as *;
.first__block {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 56px;
    padding: 68px 48px 84px 44px;
    background-color: var(--bg_1);
    border-radius: 24px;
    background-image: url('/icons/services/personal/questions-bg.png');
    background-position: bottom;
    background-size: cover;
    color: var(--white);

    @include respond(laptop) {
      padding: 56px 32px;
      row-gap: 46px;
    }

    @include respond(tablet) {
      padding: 40px 16px;
      border-radius: 0;
      background-image: none;
      row-gap: 32px;
    }
  }

  &__title {
    @include tTitle_1_bold;

    max-width: 480px;
    margin: 0;
    line-height: 72px;
    display: flex;
    flex-direction: column;

    b {
      font-weight: 700;
    }

    @include respond(laptop) {
      font-size: 48px;
      line-height: 60px;
    }

    @include respond(tablet) {
      font-size: 28px;
      line-height: 36px;
    }
  }
}

.answers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 46px;

  @include respond(laptop) {
    column-gap: 25px;
  }

  @include respond(laptop) {
    row-gap: 32px;
  }

  @include respond(tablet) {
    grid-template-columns: 1fr;
  }

  .answer {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    @include respond(tablet) {
      display: grid;
      grid-template-columns: max-content auto;
      column-gap: 16px;
    }

    &__icon {
      width: 66px;
      height: 66px;

      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      background-color: rgb(255 255 255 / 14%);

      @include respond(tablet) {
        width: 48px;
        height: 48px;
      }
    }

    &__text {
      margin: 0;

      @include tSubtitle;

      &_second {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
      }

      &__wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
      }
    }
  }
}
