@use "src/_app/styles/mixins.scss" as *;
.container {
  margin-top: 56px;

  @include respond(tablet) {
    margin-top: 32px;
  }

  @include respond(tabletS) {
    margin-top: 40px;
  }
}

.trading__container {
  margin-top: 112px;

  @include respond(tabletS) {
    margin-top: 64px;
  }
}

.subtitle {
  margin-top: 36px;

  @include respond(tablet) {
    margin-top: 24px;
  }

  @include respond(tabletS) {
    display: none;
  }
}

.description {
  margin-top: 12px;
}

.longLang {
  word-break: break-all;
}
