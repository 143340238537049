@use "src/_app/styles/mixins.scss" as *;
.available__coins {
  background-color: var(--bg_41);

  &__container {
    margin: 0 auto;
    max-width: 1280px;
    padding: 100px 0 56px;

    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 48px;

    @include respond(laptopL) {
      padding: 100px 80px 56px;
    }

    @include respond(laptop) {
      padding: 100px 32px 56px;
    }

    @include respond(tablet) {
      padding: 40px 16px 32px;
    }

    &_bottom {
      padding: 48px 0 100px;

      @include respond(tablet) {
        display: none;
      }
    }

    &_dark {
      background-image: url('/icons/common/staking-stars.svg');
      background-repeat: no-repeat;
      background-position: center;

      @include respond(tablet) {
        background-image: unset;
      }
    }
  }

  &__title {
    @include tTitle_1_bold;
    max-width: 766px;
    width: 100%;
    line-height: 72px;
    text-align: center;
    margin: 0 0 8px;

    @include respond(tablet) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  &__button {
    @include respond(tabletS) {
      display: none;
    }
  }

  .getStarted__button {
    min-width: 136px;
  }
}
