@use "src/_app/styles/mixins.scss" as *;
.progressBar {
  width: fit-content;
  height: 8px;
  display: flex;
  flex-flow: row nowrap;
  column-gap: 8px;
  margin: 16px auto 0;

  &__item {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--border_9);
    transition: background-color 0.3s ease;

    &.active {
      background-color: var(--primary);
    }
  }
}
