@use "src/_app/styles/mixins.scss" as *;
.comments_list_item_wrapper {
  width: 100%;

  &_answer {
    margin-top: 12px;
  }

  .comments_list_item {
    display: grid;
    grid-template-columns: max-content calc(100% - 16px - 44px);
    gap: 16px;

    @include respond(tabletS) {
      display: block;
    }

    .desktop_avatar {
      @include respond(tabletS) {
        display: none;
      }
    }

    .comments_list_item_content_wrapper {
      .comments_list_item_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;

        .comments_list_item_content_info {
          display: grid;
          grid-template-columns: max-content auto;
          gap: 8px;

          @include respond(tabletS) {
            gap: 0 16px;
            grid-template-rows: 1fr 1fr;
            height: 44px;
          }

          .mobile_avatar {
            grid-row: 1 / 3;
            display: none;

            @include respond(tabletS) {
              display: block;
            }
          }

          p.nickname {
            @include tSubtitle_Semibold;
          }

          p.date {
            @include tSubtitle;
            color: var(--text_1);

            @include respond(tabletS) {
              @include tCaption;
              grid-column-start: 2;
              grid-row-start: 2;
            }
          }
        }

        .comments_list_item_content_text {
          @include tSubtitle;
          overflow-wrap: break-word;

          @include respond(tabletS) {
            margin-top: 4px;
          }
        }

        .comments_list_item_respond_button {
          width: max-content;
        }
      }
    }
  }
}

.avatar {
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background: var(--bg_4);
  color: var(--white);
  border-radius: 50%;

  &_small {
    width: 36px;
    height: 36px;
  }

  &_cube {
    svg path {
      fill: var(--white);
    }
  }
}

.comment__answer {
  background-color: red;
}
