@use "src/_app/styles/mixins.scss" as *;
@mixin h1 {
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
}

.container {
  background: #000;
  color: white;
  padding: 16px 0;
  overflow-x: hidden;

  &__brokerProgram {
    text-align: center;
    padding: 304px 44px 248px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: 1440px 800px;
    background-image: url('/img/services/trading/mercuryo2.png');
    width: 100%;

    &__noise {
      background-image: url('/icons/affiliate/noise.webp');
      mix-blend-mode: overlay;
      top: 90px;
      left: 0;
      position: absolute;
      width: 100%;
      height: calc(100% - 90px);
      z-index: 1;
      opacity: 0.65;
      pointer-events: none;

      &::after {
        content: '';
        position: absolute;
        bottom: -20px;
        width: 100%;
        height: 80px;
        z-index: 20;

        @include respond(tasbletS) {
          top: 84px;
          left: 100px;
        }
      }

      @include respond(laptop) {
        top: 84px;
      }

      @include respond(mobileL) {
        top: 58px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 300px;
      height: 300px;
      border-radius: 50%;
      background: #a1a1a1;
      filter: blur(200.75px);
      transform: translate3d(0, 0, 0);
      top: -40px;
      left: -100px;
      z-index: 0;

      @include respond(tablet) {
        width: 254px;
        height: 254px;
        bottom: -168px;
        left: 100px;
      }

      @include respond(tabletS) {
        filter: blur(170px);
        transform: translate3d(0, 0, 0);
        width: 234px;
        height: 194px;
        bottom: -168px;
        right: -1126px;
      }
    }

    &__gradient {
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 246px;
        background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
        z-index: 20;

        @include respond(tabletS) {
          height: 80px;
        }
      }
    }

    @include respond(tabletS) {
      background-size: 170%;
      padding: 92px 44px 86px;
      background-position: 15% 25%;
    }
  }

  &__partners {
    display: flex;
    flex-direction: column;
    padding: 56px 0;

    @include respond(laptop) {
      padding: 0 16px 56px;
    }

    @include respond(tabletS) {
      padding: 56px 16px;
    }
  }

  &__offer {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 320px;
      height: 320px;
      background: linear-gradient(315.48deg, #111 21.75%, #777 86.97%);
      filter: blur(140px);
      transform: translate3d(0, 0, 0);
      bottom: -200px;
      inset-inline-end: -180px;
      z-index: 2;

      @include respond(tabletS) {
        display: none;
      }
    }
  }

  &__join {
    width: 100%;
    text-align: center;
    padding: 198px 0;
    background-image: url('/img/services/trading/background-join.png');
    background-repeat: no-repeat;
    background-size: 1920px 704px;
    background-position: center;
    position: relative;

    @media only screen and (width > 1920px) {
      mask-image: linear-gradient(to right, black 70%, transparent 85%);
    }

    @include respond(tabletS) {
      padding: 158px 0;
      background-size: 1440px 528px;
    }

    &::before {
      content: '';
      position: absolute;
      background: var(--white);
      width: 100px;
      height: 100px;
      filter: blur(130px);
      transform: translate3d(0, 0, 0);
      bottom: 162px;
      inset-inline-end: -50px;

      @include respond(tabletS) {
        display: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      background: var(--white);
      width: 100px;
      height: 100px;
      filter: blur(150px);
      transform: translate3d(0, 0, 0);
      bottom: 162px;
      inset-inline-start: 0;
      top: 45%;
      z-index: 100;

      @include respond(tabletS) {
        display: none;
      }
    }
  }

  &__contactForm {
    padding: 120px 80px;
    max-width: 1280px;
    margin: 0 auto;

    @include respond(laptop) {
      padding: 120px 32px;
    }

    @include respond(tabletS) {
      padding: 40px 16px 56px;
    }
  }
}
