@use "src/_app/styles/mixins.scss" as *;
.comments_container {
  width: 100%;
  padding: 56px 80px;
  background: var(--bg_3);
  color: var(--primary);
  border-bottom: 1px solid var(--bg_5);

  @include respond(laptop) {
    padding: 56px 16px;
  }

  @include respond(tabletS) {
    padding: 32px 16px;
  }

  .comments {
    width: 100%;
    max-width: 774px;
    margin: auto;

    .comments_new_login {
      font-weight: 500;

      .comment_login_btn {
        @include respond(tabletS) {
          display: block;
          width: 100%;
        }
      }
    }

    .comments_title {
      display: flex;
      gap: 8px;
      margin-bottom: 32px;
      text-transform: capitalize;

      p {
        @include tTitle_2_demibold;

        @include respond(tabletS) {
          @include tTitle_3-demibold;
        }

        &:last-child {
          @include tTitle_2;
          color: var(--text_1);

          @include respond(tabletS) {
            @include tTitle_3;
          }
        }
      }
    }

    .comments_new {
      @include sElevation_6;
      border: var(--blogCommentBorder);
      border-radius: 16px;
      overflow: hidden;
      background: var(--bg_10);
      padding: 16px 24px;

      @include respond(tabletS) {
        padding: 16px;
      }

      &.login {
        padding: 24px;
        border: none;
      }

      &.focused {
        border: 1px solid #fff;
      }

      .comments_new_input {
        display: flex;
        flex-direction: column;

        textarea {
          border: none;
          resize: none;
          outline: none;
          max-height: 200px;
          padding: 10px;
          background: transparent;
          color: var(--primary);

          @include tSubtitle;

          &::placeholder {
            vertical-align: middle;
          }
        }

        .comments_new_input_btns {
          align-self: flex-end;
          display: flex;
          align-items: flex-end;
          gap: 8px;
        }
      }

      .comments_new_login {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respond(tabletS) {
          flex-direction: column;
          gap: 24px;
        }

        p {
          @include tTitle_3-demibold;

          @include respond(tabletS) {
            text-align: center;
          }
        }
      }
    }

    .comments_loading {
      display: flex;
      flex-direction: column;
    }

    .comments_list {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      gap: 32px;
      align-items: center;

      @include respond(tabletS) {
        gap: 24px;
      }
    }
  }

  p {
    margin: 0;
  }
}
