@use "src/_app/styles/mixins.scss" as *;
.post {
  width: 302px;
  height: 206px;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid var(--border_blog-card);
  border-radius: 20px;
  position: relative;
  padding: 16px;
  z-index: 1;
  margin-right: 24px;

  @include rtl {
    margin-right: 0;
    margin-left: 24px;
  }

  @include respond(tablet) {
    margin-right: 16px;
    width: 266px;
    height: 180px;

    @include rtl {
      margin-right: 0;
      margin-left: 16px;
    }
  }

  &.last {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 101%;
    bottom: -2px;
    left: 0;
    background: linear-gradient(180deg, rgb(0 0 0 / 0%) 27.43%, #000 100%);
    z-index: 2;
    border-radius: 20px;
    overflow: hidden;
  }

  &__image {
    position: absolute;
    z-index: 0;
    overflow: hidden;
    max-width: 105%;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }

  &__title {
    position: relative;
    z-index: 3;

    @include tSubtitle_Semibold;
    text-align: start;
    letter-spacing: -0.12px;
    color: #fff;
    margin: 0;

    @include respond(tablet) {
      @include tCaption_Semibold;
    }
  }
}
