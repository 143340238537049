@use "src/_app/styles/mixins.scss" as *;
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  padding: 12px 16px;
  width: 100%;
  border-radius: 14px;
  background: #252525;

  & > :first-child {
    color: #b1b1b1;
  }

  & > :last-child {
    color: #fff;
  }

  @include respond(tabletS) {
    justify-content: space-between;
    flex-direction: row;
    padding: 0;
    background: none;
  }
}

div.skeleton {
  border-radius: 10px;
  background-image: linear-gradient(
    90deg,
    #292929 0,
    #212121 40px,
    #292929 80px
  );
  background-size: 200%;
  width: 100%;
  height: 70px;

  @include respond(tabletS) {
    height: 20px;
  }
}
