@use "src/_app/styles/mixins.scss" as *;
.transItem {
  color: var(--primary);
  text-align: start;

  .itemAddress {
    vertical-align: middle;
    display: flex;
    justify-content: space-between;

    .address {
      word-break: break-all;
      vertical-align: middle;
      text-align: start;

      @include tSubtitle;
    }

    .buttonCopy {
      padding: 0;
      background: none;
      border: none;
      align-items: center;
      justify-content: center;
      display: inline-block;
      line-height: 0;
      cursor: pointer;
      vertical-align: middle;
      margin-left: 4px;

      svg {
        color: var(--toggleText);
      }
    }
  }

  .itemAmount {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.recipients {
      flex-direction: row-reverse;
      justify-content: flex-end;

      .amountLink {
        transform: rotate(90deg);
      }
    }

    .amountLink {
      background: none;
      border: none;
      padding: 0;
      color: var(--text_4);
      margin-right: 4px;
      line-height: 0;
      cursor: pointer;
    }

    .amountValue {
      @include tSubtitle;
      color: var(--text4);
    }
  }
}

.coinBase {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @include tSubtitle_Semibold;

  span {
    margin-top: 4px;
  }
}
