@use "src/_app/styles/mixins.scss" as *;
.second__block__accordion {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  &__row {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    padding: 24px;
    border-radius: 24px;
    background-color: var(--bg_29);

    @include sElevation_7;

    @include respond(tablet) {
      padding: 24px 16px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    p {
      @include tTitle_3-semibold;
      margin: 0;
    }

    svg path {
      fill: var(--primary);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    p {
      &:first-child {
        margin-top: 24px;
      }
      margin: 0;

      @include tButton_2;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
