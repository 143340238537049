@use "src/_app/styles/mixins.scss" as *;
.transactionCard {
  background-color: var(--bg_6);

  ul {
    margin: 0;
    list-style: none;
    padding: 0;

    li {
      margin: 0;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border: none;
    width: 100%;
    background: none;
    cursor: pointer;

    .title {
      @include tSubtitle_Semibold;
      color: var(--primary);
    }

    svg {
      transform: rotate(180deg);

      path {
        fill: var(--primary);
      }
    }

    .iconExpand {
      display: flex;
      align-items: center;
      justify-content: center;

      @include transition;

      &.active {
        transform: rotate(180deg);
      }
    }
  }

  .list {
    overflow: hidden;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.transItem {
  padding: 16px 24px;
  background-color: var(--bg_2);
  border-radius: 16px;
  overflow: hidden;
  color: var(--primary);
  max-width: 364px;
  flex-shrink: 0;

  @include respond(tabletS) {
    max-width: 100%;
  }

  &:last-of-type {
    margin-bottom: 24px;
  }

  .itemAddress {
    vertical-align: middle;
    display: inline-block;

    .address {
      word-break: break-all;
      vertical-align: middle;
      text-align: center;

      @include tSubtitle;
    }

    .buttonCopy {
      padding: 0;
      background: none;
      border: none;
      align-items: center;
      justify-content: center;
      display: inline-block;
      line-height: 0;
      cursor: pointer;
      vertical-align: middle;
      margin-left: 4px;
    }
  }

  .itemAmount {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.recipients {
      flex-direction: row-reverse;
      justify-content: flex-end;

      .amountLink {
        transform: rotate(180deg);
        margin-right: 0;
        margin-left: 4px;
      }
    }

    .amountLink {
      background: none;
      border: none;
      padding: 0;
      color: var(--text_4);
      margin-right: 4px;
      line-height: 0;
      cursor: pointer;
    }

    .amountValue {
      @include tSubtitle;
      color: var(--text4);
    }
  }
}

.coinBase {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @include tSubtitle_Semibold;

  span {
    margin-top: 4px;
  }
}
