@use "src/_app/styles/mixins.scss" as *;
.container {
  &.stepsContainer {
    background-color: var(--bg_mass_block);
    color: var(--primary);
    padding-bottom: 0;
    overflow: hidden;

    .content {
      @include contentWidth;
      max-width: 980px;
      margin: 0 auto;

      @include respond(tablet) {
        padding-bottom: 16px;
      }
    }
  }

  &.blog {
    $pad: 26px;

    background: var(--bg_26);
    color: var(--primary);

    .blog__content {
      @include contentWidth;
      display: flex;
      flex-direction: column;

      h3 {
        @include tTitle_1_main;
        margin: 0 0 56px;

        @include respond(laptop) {
          @include tTitle_2_main;
        }

        @include respond(tabletS) {
          @include tTitle_1_demibold;
          margin-bottom: 30px;
        }
      }

      .swiper_item_container {
        padding: 0;

        &:last-child {
          margin-bottom: 0 !important;
        }

        .swiper_item {
          border-radius: 16px;
          overflow: hidden;
          padding: $pad;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: var(--bg_6);

          @include sElevation_5;

          h3 {
            margin-top: 16px;
            margin-bottom: 8px;

            @include tTitle_2_semibold;
          }

          p {
            margin: 0;

            @include tCaption;
            color: var(--text_1);
          }
        }
      }

      .swiper_list {
        overflow: visible;
      }
    }
  }

  &.how_it_works_accordion {
    background: var(--bg_mass_block);

    .how_it_works__wrapper {
      max-width: 980px;
      margin: 0 auto;
    }
  }
}
