@use "src/_app/styles/mixins.scss" as *;
.link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  color: #828282;
  width: fit-content;
  margin-top: 12px;

  @include transition;

  svg {
    path {
      @include transition;
    }
  }

  &:hover {
    color: #fff;

    svg {
      path {
        @include transition;
        fill: #fff;
      }
    }
  }
}

.description {
  max-width: 1070px;
  margin-top: 36px;

  @include respond(tablet) {
    margin-top: 24px;
  }

  @include respond(tabletS) {
    margin-top: 32px;
  }
}

.cards__container {
  margin-top: 24px;
}
