@use "src/_app/styles/mixins.scss" as *;
.icon_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;

  @include transition;
  background: var(--bg_neutral_80);
  color: var(--secondary);
}

.small {
  width: 40px;
  height: 40px;
}

.medium {
  width: 44px;
  height: 44px;
}

.big {
  width: 48px;
  height: 48px;
}

.action {
  cursor: pointer;
}
