@use "src/_app/styles/mixins.scss" as *;
@mixin contentWidth {
  width: 100%;
  max-width: 1280px;
  margin: 100px auto;
}

.block__wrapper {
  @include contentWidth;

  background: #000;

  @include respond(mobileL) {
    margin: 40px auto;
  }
}

.block_header {
  max-width: 680px;
  margin: 0 auto 56px;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  @include respond(mobileL) {
    align-items: flex-start;
    margin: 0 auto 32px;
  }

  img {
    position: absolute;
    z-index: 0;

    @include respond(mobileL) {
      display: none;
    }
  }

  h2 {
    text-align: center;

    @include tTitle_1_main;
    color: var(--white);
    margin: 0 0 32px;

    @include respond(mobileL) {
      text-align: left;
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 0;
    }
  }

  .block_header__button {
    z-index: 1;

    button {
      @include tSubtitle_Semibold;
      cursor: pointer;
    }

    @include respond(mobileL) {
      display: none;
    }
  }

  a.getStarted_Button {
    height: 60px;

    p {
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}
