@use "src/_app/styles/mixins.scss" as *;
.swiper {
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &.plug {
    max-width: 100%;
    width: fit-content;
    margin: 0 auto;
  }

  &_slide,
  .swiper-slide {
    padding: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
    position: relative;

    a {
      display: block !important;
      height: 100%;
      max-width: 100%;
      overflow: hidden;
    }

    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
