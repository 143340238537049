@use "src/_app/styles/mixins.scss" as *;
.description {
  margin-top: 36px;

  @include respond(tablet) {
    margin-top: 24px;
  }

  @include respond(tabletS) {
    margin-top: 32px;
  }
}

.link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  color: #828282;
  width: fit-content;
  margin-top: 12px;

  @include transition;

  svg {
    path {
      @include transition;
    }
  }

  &:hover {
    color: #fff;

    svg {
      path {
        @include transition;
        fill: #fff;
      }
    }
  }
}

div.cards__container {
  margin-top: 24px;
  width: fit-content;
  grid-template-columns: 1fr 1fr;

  & > div {
    min-width: 376px;
  }

  @include respond(laptop) {
    width: 100%;

    & > div {
      min-width: auto;
    }
  }
}
