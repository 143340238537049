@use "src/_app/styles/mixins.scss" as *;
.content {
  padding: 24px;
  display: flex;
  flex-flow: column nowrap;
  row-gap: 16px;
  width: 352px;
  background: var(--bg_2);
  border-radius: 16px;
  list-style: none;
  margin: 0;

  @include respond(laptop) {
    width: 304px;
  }
}
