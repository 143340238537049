@use "src/_app/styles/mixins.scss" as *;
div.accordionItem__wrapper {
  img {
    object-fit: contain;
    width: 32px;
    height: 32px;
  }
  border: none;
  background: #141414;

  @include transition;

  &.open {
    background: #252525;
  }

  .accordionItem__header {
    color: #fff;
  }

  .accordionItem__button {
    svg {
      path {
        color: #fff;
      }
    }
  }

  div.accordionItem__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #2d2d2d;
    padding: 0;

    & > :not(:first-child) {
      border-top: 1px solid #2d2d2d;
    }
  }
}
