@use "src/_app/styles/mixins.scss" as *;
.container {
  display: flex;
  flex-flow: column;
  gap: 24px;

  h2 {
    margin-top: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
}

.wrapper {
  height: max-content;
  min-height: 240px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-auto-rows: 0;
  column-gap: 24px;

  @include respond(laptop) {
    grid-template-columns: 2fr 1fr;
  }

  @include respond(tablet) {
    grid-template-columns: 1fr;
  }

  @include respond(mobileL) {
    min-height: 350px;
  }
}

.footer {
  margin-top: auto;
  padding-top: 10px;
}

.link {
  width: fit-content !important;
  height: auto;
  padding: 8px 16px;
  background-color: var(--bg_neutral_30);
  border-radius: 10px;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.12px;
}
