@use "src/_app/styles/mixins.scss" as *;
.infoController {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  button {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    cursor: pointer;

    @include tButton;
    color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;

    .infoIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 6px;

      @include transition;
      transform: rotate(180deg);

      &.active {
        transform: rotate(0deg);
      }
    }

    .downloadIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;

      &__button {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          color: var(--primary);
          margin: 0;

          @include tButton_2;
        }
      }
    }
  }
}

.additionalInfo {
  margin-top: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 8px;

  @include respond(tablet) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @include respond(mobileL) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }

  .infoItem {
    min-height: 64px;
    padding: 20px;
    gap: 16px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    background-color: var(--block_bg_cards);
    align-items: center;
    max-width: 100%;
    flex-wrap: wrap;

    .itemTitle {
      color: var(--text_1);
    }
  }

  .infoItem_gas {
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    gap: 28px;

    &__body {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0;
      }
    }
  }
}

.additionalInfo_tron {
  grid-template-columns: 1fr 1fr;

  @include respond(tablet) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

.additionalInfo_gas {
  grid-template-columns: 1fr;

  @include respond(tablet) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}

.address {
  display: flex;
  align-items: center;
  gap: 16px;

  svg {
    color: var(--tertiary);
  }
}

.infoItems {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.itemValue_desktop {
  display: inline;

  @include respond(tabletS) {
    display: none;
  }
}

.itemValue_mobile {
  display: none;

  @include respond(tabletS) {
    display: inline;
  }
}
