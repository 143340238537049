@use "src/_app/styles/mixins.scss" as *;
@mixin blockBorderRadius($radius) {
  border-radius: $radius $radius 0 0;
}

@mixin size($size) {
  width: $size;
  height: $size;
}

.gradient {
  @include blockBorderRadius(32px);

  background: var(--black_gradient_6);
  padding: 1px;
  z-index: 1;
  position: relative;

  @include respond(tablet) {
    background-color: unset;
  }
}

.container {
  @include blockBorderRadius(32px);

  background-color: var(--bg_6);
  padding: 22px 16px 24px;

  display: flex;
  flex-direction: column;
  row-gap: 28px;

  .title {
    @include tSubtitle;
    text-align: center;

    margin: 0 0 3px;
  }

  .select {
    &__container {
      display: flex;
      flex-direction: column;
      row-gap: 28px;

      color: var(--primary);
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    &__label {
      @include Label;
      margin: 0;
    }

    &__hint {
      @include tCaption;
      line-height: 22px;
      letter-spacing: -0.12px;
      margin: 0;

      color: var(--text_6);
    }
  }

  .radio {
    &__group {
      &__container {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
      }

      &__label {
        @include Label;
        margin: 0;
      }
    }

    &__wrapper {
      padding: 12px 16px;
      border-radius: 10px;
      outline: 1px solid var(--border_16);

      display: flex;
      align-items: center;
      column-gap: 12px;

      &_active {
        outline: 1px solid var(--border_2_inverted);
      }
    }

    &__item {
      @include size(24px);
      border-radius: 50%;
      border: 2px solid var(--border_radio);

      display: flex;
      align-items: center;
      justify-content: center;

      &_active {
        border: none;
        background-color: var(--primary);
      }

      &__circle {
        background-color: var(--bg_6);
        border-radius: 50%;

        @include size(6px);
      }
    }

    &__title {
      margin: 0;
    }
  }
}

.coin__wrapper {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
