@use "src/_app/styles/mixins.scss" as *;
.swiperWrapper {
  overflow: hidden;
  display: flex;

  &.bodySwiperWrapper {
    min-width: 250px;

    & > div:first-of-type {
      width: 100%;
    }
  }

  .imgSwiper {
    flex: 1;
  }
}

.swiperCard {
  display: grid;
  grid-template-columns: minmax(100px, 200px) 1fr;

  .cardBody {
    margin-top: -25px;
    height: calc(100% + 50px);
    user-select: none;
  }

  .img {
    margin-top: -25px;
    height: calc(100% + 50px);
    background-size: cover;
    background-image: none;
    background-repeat: no-repeat;
    background-position: 50% 0;
  }

  .footer {
    margin-top: auto;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
    padding-inline-end: 68px;
  }

  .button {
    width: fit-content;
    align-items: center;
    color: var(--text_icon_inverted);

    > button {
      height: 40px !important;
      padding: 8px 16px;
    }

    > span {
      width: max-content;
    }
  }

  @include respond(mobileL) {
    grid-template-columns: 1fr;

    .img {
      height: 180px;
    }
  }
}

.swiperNavigation {
  z-index: 1;
  position: absolute;
  inset-block-end: 20px;
  inset-inline-start: 20px;
  background-color: var(--select_item_hover);
  border-radius: 34px;
  padding: 8px;
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  box-sizing: content-box;

  @include transition;

  .point {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: var(--neutral_40);

    @include transition;

    &.active {
      width: 37px;
      background: var(--primary);
    }
  }

  @include respond(mobileL) {
    inset-inline-start: unset;
    inset-inline-end: 16px;
    inset-block-start: 144px;
    inset-block-end: unset;
  }
}
